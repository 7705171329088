import { MidTermRatingResponse, RatingResponse, RefundDetails } from "raci-motorendorsements-clientproxy";
import { SessionState } from "raci-react-library";
import { UseFormReturn } from "react-hook-form";

export const FORM_NAME_SELECTED_FREQUENCY = "frequency";
export const FORM_NAME_SELECTED_EXCESS = "excess";
export const FORM_NAME_SELECTED_AGREED_VALUE = "agreedValue";
export const FORM_NAME_SELECTED_AGREED_VALUE_CHANGED = "agreedValueChanged";
export const FORM_NAME_SELECTED_HIRE_CAR = "hasHireCar";
export const FORM_NAME_SELECTED_NCB_PROTECTION = "hasNcbProtection";
export const FORM_NAME_REFUND_DETAILS = "refundDetails";
export const FORM_NAME_PREMIUM_CHANGE_STATE = "premiumChangeState";
export const FORM_NAME_PREMIUM_DIFFERENCE = "premiumDifference";
export const FORM_NAME_NEXT_PREMIUM_TOTAL = "nextPremiumTotal";

export enum PremiumChangeState {
  PAYMENT,
  REFUND,
  REVIEW,
}

export interface YourPremiumFormState extends SessionState {
  frequency: string;
  excess: number;
  agreedValue: number | undefined;
  /** Set agreed value to undefined to handle sum insured that is not a whole number in the BFF */
  agreedValueChanged: boolean;
  isAgreedValueValid: boolean | undefined;
  hasHireCar?: boolean;
  hasNcbProtection?: boolean;
  ratingResponse: RatingResponse | MidTermRatingResponse | undefined;
  refundDetails?: RefundDetails;
  premiumChangeState?: PremiumChangeState;
  premiumDifference?: number;
  nextPremiumTotal?: number;
  productVersion?: number;
}

export interface YourPremiumFormProps {
  form: UseFormReturn<YourPremiumFormState>;
  onSubmit: (answers: YourPremiumFormState) => Promise<void>;
}
