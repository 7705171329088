import { useContext } from "react";
import { PolicyNumberContext } from "../../components/PolicyNumberProvider";

export const usePolicyNumber = () => {
  const policyNumber = useContext(PolicyNumberContext);

  if (policyNumber === undefined) {
    throw new Error("Policy number not found");
  }

  return policyNumber ?? "";
};

export default usePolicyNumber;
