import { AUD_CURRENCY, currencyFormatter, EN_AU_LOCALE } from "raci-react-library";

export const createId = (prefix: string, suffix: string) => {
  return `${prefix}-${suffix}`;
};

export const formatCurrency = (value: number, shouldStripZeroCents: boolean | undefined = true) => {
  return currencyFormatter(value, {
    withDecimals: true,
    stripZeroCents: shouldStripZeroCents,
  });
};

export const formatCurrencyNoDecimals = (value: number) => {
  return Intl.NumberFormat(EN_AU_LOCALE, {
    style: "currency",
    currency: AUD_CURRENCY,
    minimumFractionDigits: 0,
  }).format(value);
};

export const createArrayRange = (start: number, stop: number) => {
  return Array.from({ length: stop - start + 1 }, (_, index) => start + index);
};

export const isEmptyObject = (object: Object) => {
  return Object.keys(object).length === 0;
};
