import {
  BaseRatingRequest,
  EndorsePolicyResponse,
  MidTermRatingResponse,
  PolicyDetailsResponsePaymentFlow,
  RatingResponse,
} from "raci-motorendorsements-clientproxy";
import { AtomEffect, DefaultValue, atom } from "recoil";
import { PremiumChangeState } from "../../views/YourPremium/types";
import {
  ATOM_ENDORSEMENT,
  ATOM_FORM_COMPLETED,
  ATOM_MIDTERM_RATING,
  ATOM_NCB_PRODUCT_VERSION,
  ATOM_POLICY_PAYMENT_STATE,
  ATOM_PREVIOUS_RATING_REQUEST,
  ATOM_RATING,
  ATOM_REGISTRATION_LOOKUP,
  ATOM_ROUTE_STATE,
  ATOM_SESSION_ID,
  ATOM_SHOW_WESTPAC_RETRY_DIALOG,
  ATOM_TIMEOUT,
  TIMEOUT_30_MINUTES_MS,
} from "../constants";
import { PolicyPaymentState, RegistrationLookupData } from "./types";

// https://recoiljs.org/docs/guides/atom-effects/#local-storage-persistence
const sessionStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export interface RouteState {
  policyNumber?: string;
  isMidterm?: boolean;
  isVehicleConfirmed?: boolean;
  premiumChangeState?: PremiumChangeState;
  paymentFlow?: PolicyDetailsResponsePaymentFlow;
}

export const routeStateAtom = atom<RouteState>({
  key: ATOM_ROUTE_STATE,
  default: {},
  effects: [sessionStorageEffect<RouteState>(ATOM_ROUTE_STATE)],
});

export const ratingResponseAtom = atom<RatingResponse | undefined>({
  key: ATOM_RATING,
  default: undefined,
  effects: [sessionStorageEffect<RatingResponse | undefined>(ATOM_RATING)],
});

export const midTermRatingResponseAtom = atom<MidTermRatingResponse | undefined>({
  key: ATOM_MIDTERM_RATING,
  default: undefined,
  effects: [sessionStorageEffect<MidTermRatingResponse | undefined>(ATOM_MIDTERM_RATING)],
});

export const endorsementAtom = atom<EndorsePolicyResponse | undefined>({
  key: ATOM_ENDORSEMENT,
  default: undefined,
  effects: [sessionStorageEffect<EndorsePolicyResponse | undefined>(ATOM_ENDORSEMENT)],
});

export const previousRatingRequestAtom = atom<BaseRatingRequest | undefined>({
  key: ATOM_PREVIOUS_RATING_REQUEST,
  default: undefined,
  effects: [sessionStorageEffect<BaseRatingRequest | undefined>(ATOM_PREVIOUS_RATING_REQUEST)],
});

export const sessionIdAtom = atom<string>({
  key: ATOM_SESSION_ID,
  default: "",
  effects: [sessionStorageEffect<string>(ATOM_SESSION_ID)],
});

export const registrationLookupAtom = atom<RegistrationLookupData>({
  key: ATOM_REGISTRATION_LOOKUP,
  default: undefined,
  effects: [sessionStorageEffect<RegistrationLookupData>(ATOM_REGISTRATION_LOOKUP)],
});

export const atomTimeoutTime = atom<number>({
  key: ATOM_TIMEOUT,
  default: new Date().getTime() + TIMEOUT_30_MINUTES_MS,
  effects: [sessionStorageEffect<number>(ATOM_TIMEOUT)],
});

export const formCompletedAtom = atom<boolean>({
  key: ATOM_FORM_COMPLETED,
  default: false,
  effects: [sessionStorageEffect<boolean>(ATOM_FORM_COMPLETED)],
});

export const policyPaymentStateAtom = atom<PolicyPaymentState>({
  key: ATOM_POLICY_PAYMENT_STATE,
  default: {
    isPaymentMethodLocked: false,
    isCreditCardSelected: false,
    isRealtimePayment: false,
  },
  effects: [sessionStorageEffect<PolicyPaymentState>(ATOM_POLICY_PAYMENT_STATE)],
});

export const westpacRetryDialogAtom = atom<boolean>({
  key: ATOM_SHOW_WESTPAC_RETRY_DIALOG,
  default: false,
  effects: [sessionStorageEffect<boolean>(ATOM_SHOW_WESTPAC_RETRY_DIALOG)],
});

export const productVersionIdAtom = atom<number>({
  key: ATOM_NCB_PRODUCT_VERSION,
  default: 0,
  effects: [sessionStorageEffect<number>(ATOM_NCB_PRODUCT_VERSION)],
});
