import { createContext, ReactNode, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { CONTEXT_POLICY_NUMBER_SESSION_KEY } from "../../constants";

export const PolicyNumberContext = createContext<string | null | undefined>(undefined);

export const PolicyNumberProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams();
  const queryParamPolicyNumber = searchParams.get("policyNumber");

  const sessionStoragePolicyNumber = sessionStorage.getItem(CONTEXT_POLICY_NUMBER_SESSION_KEY);

  useEffect(() => {
    queryParamPolicyNumber && sessionStorage.setItem(CONTEXT_POLICY_NUMBER_SESSION_KEY, queryParamPolicyNumber ?? "");
  }, [queryParamPolicyNumber]);

  return (
    <PolicyNumberContext.Provider value={queryParamPolicyNumber ?? sessionStoragePolicyNumber}>
      {children}
    </PolicyNumberContext.Provider>
  );
};

export default PolicyNumberProvider;
