import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { routeStateAtom } from "../../atoms";
import { getPageTitle } from "../../constants";
import { allRoutes } from "../routes.config";

const defaultTitle = process.env.REACT_APP_SITE_TITLE || "";
const envPrefix = process.env.REACT_APP_ENVIRONMENT !== "PRD" ? `**${process.env.REACT_APP_ENVIRONMENT}** - ` : "";

const createPageTitle = (page: string, isMidterm?: boolean) => {
  return `${envPrefix} ${getPageTitle(isMidterm)} - Motor - ${page}`;
};

export const TitleGuard: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const { isMidterm } = useRecoilValue(routeStateAtom);

  useEffect(() => {
    if (document) {
      const route = allRoutes.find((item) => item.path === location.pathname);
      document.title = route?.name ? createPageTitle(route.name, isMidterm) : defaultTitle;
    }
  }, [location.pathname, isMidterm]);

  return <>{children}</>;
};
