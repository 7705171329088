import { event, gtm } from "@racwa/analytics";
import { MobileStepperProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL, SidebarContent } from "raci-react-library";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { routeStateAtom } from "../../atoms";
import { getPageTitle } from "../../constants";
import useRoutes from "../../routing/useRoutes";
import usePage from "../usePage";

export const useStepperPage = (): RacwaStepperTemplateProps => {
  const commonPageProps = usePage();
  const location = useLocation();
  const { isMidterm } = useRecoilValue(routeStateAtom);

  const {
    formRoutes,
    steps,
    activeStepIndex,
    canNavigateToPreviousStep,
    canNavigateToDestinationIndex,
    navigateToPreviousStep,
    navigateToDestinationIndex,
  } = useRoutes();

  const sidebarTitle = getPageTitle(isMidterm);

  const sidebarContent = useMemo(
    () => (
      <SidebarContent
        pdsUrl={process.env.REACT_APP_RAC_PDS_PED_URL || EMPTY_URL}
        feedbackUrl={process.env.REACT_APP_RAC_FEEDBACK_URL || EMPTY_URL}
      />
    ),
    [],
  );

  const contentTitle = formRoutes.find(
    (route) => route.path.toLowerCase() === location.pathname?.toLowerCase(),
  )?.heading;

  const mobileStepperProps: MobileStepperProps = {
    hideBack: !canNavigateToPreviousStep,
    onClickBack: () => {
      if (navigateToPreviousStep && activeStepIndex !== undefined) {
        const navigationDestination = activeStepIndex === 0 ? "PCM" : steps[activeStepIndex - 1].name;
        gtm(event(`Mobile header back to ${navigationDestination}`));
        navigateToPreviousStep();
      }
    },
  };

  const onStepClick = (stepName: string, stepIndex: number) => {
    if (canNavigateToDestinationIndex(stepIndex)) {
      gtm(event(`Stepper navigate to ${stepName}`));
      navigateToDestinationIndex(stepIndex);
    }
  };

  return {
    contentTitle,
    sidebarTitle,
    sidebarContent,
    steps,
    activeStepIndex,
    mobileStepperProps,
    onStepClick,
    ...commonPageProps,
  };
};

export default useStepperPage;
