import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { sessionIdAtom } from "../../atoms";
import {
  MIDTERM_CONFIRMATION_PAGE_URL,
  RENEWAL_CONFIRMATION_PAGE_URL,
  utilityRoutes,
} from "../../routing/routes.config";
import useApiClient from "../useApiClient";

export const useGetWebChatInformation = () => {
  const client = useApiClient();
  const location = useLocation();
  const sessionId = useRecoilValue(sessionIdAtom);
  const [pointOfContactId, setPointOfContactId] = useState("");
  const [shouldRenderWebChat, setShouldRenderWebChat] = useState(false);
  const [crmId, setCrmId] = useState("");
  const disallowPages = [
    ...utilityRoutes.map((route) => route.path),
    RENEWAL_CONFIRMATION_PAGE_URL,
    MIDTERM_CONFIRMATION_PAGE_URL,
  ];
  const currentLocation = location.pathname.toLowerCase();

  const shouldGetWebChatInformation = !disallowPages?.includes(currentLocation);

  useEffect(() => {
    let isCancelled = false;

    const getWebChatId = async () => {
      try {
        if (!isCancelled && sessionId) {
          if (shouldGetWebChatInformation) {
            const response = await client.getWebChatContactId();
            setPointOfContactId(response.result.pointOfContactId);
            setCrmId(response.result.crmId ?? "");
            setShouldRenderWebChat(true);
          } else {
            setShouldRenderWebChat(false);
          }
        }
      } catch {
        setPointOfContactId("");
        setShouldRenderWebChat(false);
      }
    };

    if (sessionId) {
      getWebChatId();
    }

    return () => {
      isCancelled = true;
    };
  }, [client, currentLocation, shouldGetWebChatInformation, sessionId]);

  return { pointOfContactId, crmId, shouldRender: shouldRenderWebChat };
};

export default useGetWebChatInformation;
