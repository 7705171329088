import { TrackPageChangesProps } from "raci-react-library";
import {
  UTILITY_CALL_US_PAGE_URL,
  UTILITY_SESSION_TIMEOUT_PAGE_URL,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../routing/routes.config";

export const SESSION_KEY_PREFIX = "RAC_MEO_";
export const NON_BREAKING_SPACE = "\u00a0";
export const TIMEOUT_30_MINUTES_MS = 1800000;
export const RENEWAL_GRACE_PERIOD_IN_DAYS = 28;
export const MID_TERM_GRACE_PERIOD_IN_DAYS = 14;
export const MONIKER_EXISTING_ADDRESS = "EXISTING_ADDRESS";

// <------------------>
//  API URLs
// <------------------>
export const BFF_API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
export const LOOKUP_SERVICE_API_BASE_URL = process.env.REACT_APP_LOOKUP_SERVICE_API_BASE_URL ?? "";
export const MFA_OTP_SERVICE_API_BASE_URL = process.env.REACT_APP_MFA_OTP_SERVICE_API_BASE_URL ?? "";

// <------------------>
//  Ncb Toggle
// <------------------>
export const HIDE_NCB = true;
export const PRODUCT_VERSION = 68000023;

// <------------------>
//  MyRAC AADB2C Auth
// <------------------>
export const ADB2C_DOMAIN = process.env.REACT_APP_ADB2C_DOMAIN ?? "";
export const ADB2C_TENANT = process.env.REACT_APP_ADB2C_TENANT ?? "";
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";

// <------------------>
//  Session State Keys
// <------------------>
export const PRE_FORM_SESSION_STATE_KEY = "PreForm";
export const YOUR_CAR_SESSION_STATE_KEY = "YourCar";
export const UPDATE_YOUR_CAR_SESSION_STATE_KEY = "UpdateYourCar";
export const YOUR_CAR_DETAILS_SESSION_STATE_KEY = "YourCarDetails";
export const YOUR_CAR_USAGE_SESSION_STATE_KEY = "YourCarUsage";
export const START_DATE_SESSION_STATE_KEY = "StartDate";
export const MIDTERM_REFUND_SESSION_STATE_KEY = "MidtermRefund";
export const MIDTERM_PAYMENT_SESSION_STATE_KEY = "MidtermPayment";
export const MIDTERM_REVIEW_SESSION_STATE_KEY = "MidtermReview";
export const MIDTERM_DIRECT_DEBIT_SESSION_STATE_KEY = "MidtermDirectDebit";
export const YOUR_PREMIUM_SESSION_STATE_KEY = "YourPremium";
export const PAYMENT_DETAILS_SESSION_STATE_KEY = "PaymentDetails";
//  ⬆ The keys above are prefixed during the session state initialization stage for our pages.
export const MANUFACTURERS_SESSION_STATE_KEY = `${SESSION_KEY_PREFIX}Manufacturers`;
export const FINANCIERS_SESSION_STATE_KEY = `${SESSION_KEY_PREFIX}Financiers`;
export const PAYMENT_ACCOUNTS_SESSION_STATE_KEY = `${SESSION_KEY_PREFIX}PaymentAccounts`;
export const CONFIRMATION_SESSION_STATE_KEY = `${SESSION_KEY_PREFIX}Confirmation`;
export const HAS_TIMED_OUT_STATE_KEY = `${SESSION_KEY_PREFIX}HasTimedOut`;
export const CONTEXT_POLICY_NUMBER_SESSION_KEY = `${SESSION_KEY_PREFIX}ContextPolicyNumber`;

// <------------------>
//  Atoms
// <------------------>
export const ATOM_ROUTE_STATE = `${SESSION_KEY_PREFIX}AtomRouteState`;
export const ATOM_RATING = `${SESSION_KEY_PREFIX}AtomRating`;
export const ATOM_MIDTERM_RATING = `${SESSION_KEY_PREFIX}AtomMidTermRating`;
export const ATOM_PREVIOUS_RATING_REQUEST = `${SESSION_KEY_PREFIX}AtomPreviousRatingRequest`;
export const ATOM_ENDORSEMENT = `${SESSION_KEY_PREFIX}AtomEndorsement`;
export const ATOM_SESSION_ID = `${SESSION_KEY_PREFIX}AtomSessionId`;
export const ATOM_TIMEOUT = `${SESSION_KEY_PREFIX}AtomTimeout`;
export const ATOM_REGISTRATION_LOOKUP = `${SESSION_KEY_PREFIX}AtomRegistrationLookup`;
export const ATOM_FORM_COMPLETED = `${SESSION_KEY_PREFIX}AtomFormCompleted`;
export const ATOM_POLICY_PAYMENT_STATE = `${SESSION_KEY_PREFIX}AtomPolicyPaymentState`;
export const ATOM_SHOW_WESTPAC_RETRY_DIALOG = `${SESSION_KEY_PREFIX}AtomShowWestpacRetryDialog`;
export const ATOM_NCB_PRODUCT_VERSION = `${SESSION_KEY_PREFIX}AtomNcbProductVersionId`;

// <------------------>
//  Analytics
// <------------------>
export const trackPageChangesProps: TrackPageChangesProps = {
  customAnalyticsUrls: [],
  untrackedPages: [UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL, UTILITY_SESSION_TIMEOUT_PAGE_URL, UTILITY_CALL_US_PAGE_URL],
};

// <------------------>
//  Shield
// <------------------>
export const COVER_TYPE_MOTOR_THIRD_PARTY_FIRE_THEFT = "MTFT";

export const PAYMENT_METHOD_CASH = "Cash";

export const VEHICLE_USAGE_EXT_CODE_PRIVATE = "Private";
export const VEHICLE_USAGE_EXT_CODE_BUSINESS = "Business";
export const VEHICLE_USAGE_EXT_CODE_RIDESHARING_PART_TIME = "Rideshare";
export const VEHICLE_USAGE_EXT_CODE_COURIER_OR_DELIVERY = "CourierOrDelivery";
export const VEHICLE_USAGE_EXT_CODE_HIRED_OR_LEASED_OUT = "HiredorLeasedOut";
export const VEHICLE_USAGE_EXT_CODE_TAXI_OR_SMALL_CHARTER = "CharterVehicle";
export const VEHICLE_USAGE_EXT_CODE_LIMO_OR_CHAUFFEURED = "LimoOrChauffeured";

// <------------------>
//  Input validation patterns
// <------------------>
export const VALID_VEHICLE_REGISTRATION_PATTERN = /(?!\s)^[A-Z0-9\s]*$/i;
export const VALID_VEHICLE_REGISTRATION_MAX_LENGTH = 10;

// <------------------>
//  Page titles
// <------------------>
export const TITLE_RENEW = "Renew your policy";
export const TITLE_UPDATE = "Update your policy";

export const getPageTitle = (isMidterm?: boolean): string => {
  switch (isMidterm) {
    case true:
      return TITLE_UPDATE;
    case false:
      return TITLE_RENEW;
    default:
      return TITLE_UPDATE;
  }
};

// <------------------>
//  Content
// <------------------>
export const AMOUNT_SUBTITLE = "Monthly amounts may vary. Check your policy documents for the exact amounts.";
export const BACK_TO_MY_POLICY = "Back to my policy";
