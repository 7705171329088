import { gtm, virtualPageView } from "@racwa/analytics";
import { MotorEndorsementApiException } from "raci-motorendorsements-clientproxy";
import { HTTP_STATUS_CODE_BAD_REQUEST, HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE } from "raci-react-library";
import { lazy } from "react";
import { NavigateFunction } from "react-router-dom";

const CallUs = lazy(() => import("../../views/CallUs"));
const SessionTimeout = lazy(() => import("../../views/SessionTimeout"));
const SystemUnavailable = lazy(() => import("../../views/SystemUnavailable"));
const Roadblock = lazy(() => import("../../views/Roadblock"));

const PreForm = lazy(() => import("../../views/PreForm"));
const YourCar = lazy(() => import("../../views/YourCar"));
const UpdateYourCar = lazy(() => import("../../views/UpdateYourCar"));
const YourCarDetails = lazy(() => import("../../views/YourCarDetails"));
const YourCarUsage = lazy(() => import("../../views/YourCarUsage"));
const StartDate = lazy(() => import("../../views/StartDate"));
const YourPremium = lazy(() => import("../../views/YourPremium"));
const PaymentDetails = lazy(() => import("../../views/PaymentDetails"));
const MidTermPayment = lazy(() => import("../../views/MidtermPayment"));
const MidtermRefund = lazy(() => import("../../views/MidtermRefund"));
const MidtermReview = lazy(() => import("../../views/MidtermReview"));
const MidTermDirectDebit = lazy(() => import("../../views/MidtermDirectDebit"));
const Confirmation = lazy(() => import("../../views/Confirmation"));

export enum PreFormRoute {
  PreForm = "",
}

export enum RenewalRoutes {
  YourCar = "your-car",
  UpdateYourCar = "update-your-car",
  YourCarDetails = "your-car-details",
  YourCarUsage = "your-car-usage",
  YourPremium = "your-premium",
  Payment = "payment",
  Confirmation = "confirmation",
}

export enum MidtermRoutes {
  StartDate = "start-date",
  YourCar = "your-car",
  UpdateYourCar = "update-your-car",
  YourCarDetails = "your-car-details",
  YourCarUsage = "your-car-usage",
  YourPremium = "your-premium",
  Review = "review",
  Payment = "payment",
  Refund = "refund",
  DirectDebit = "direct-debit",
  Confirmation = "confirmation",
}

export enum UtilityRoutes {
  CallUs = "call-us",
  SessionTimeout = "408",
  SystemUnavailable = "error",
  Roadblock = "roadblock",
}

export enum PreFormPageNames {
  PreForm = "",
}

export enum RenewalPageNames {
  YourCar = "Your car",
  UpdateYourCar = "Update your car",
  YourCarDetails = "Your car details",
  YourCarUsage = "Your car usage",
  YourPremium = "Your premium",
  Payment = "Payment",
  Confirmation = "Confirmation",
}

export enum MidtermPageNames {
  StartDate = "Start date",
  YourPremium = "Your premium",
  Payment = "Payment",
  Refund = "Refund",
  Review = "Review",
}

export enum RenewalPageHeadings {
  YourCar = "Your car",
  UpdateYourCar = "Let's update your car",
  YourCarDetails = "Confirm your car details",
  YourCarUsage = "Confirm your car usage",
  YourPremium = "Here's your renewal",
  Payment = "Pay your renewal",
}

export enum MidtermPageHeadings {
  StartDate = "Start date",
  Payment = "Pay your premium",
  Refund = "Get your refund",
  YourPremium = "Here's your premium",
  Review = "Review your policy",
}

export enum UtilityPageNames {
  CallUs = "Call us",
  SessionTimeout = "Session timed out",
  SystemUnavailable = "System unavailable",
  Roadblock = "Roadblock",
}

export enum UtilityPageHeadings {
  SessionTimeout = "Uh oh!",
  SystemUnavailable = "Uh oh!",
  Roadblock = "Uh oh!",
  Default = "Uh oh!",
}

export interface Navigation {
  midterm: string;
  renewal: string;
}

const getRenewalRouteUrl = (route: RenewalRoutes | string) => `${process.env.REACT_APP_HOMEPAGE}/renew/${route}`;
const getMidtermRouteUrl = (route: MidtermRoutes | string) => `${process.env.REACT_APP_HOMEPAGE}/update/${route}`;
const getUtilityRouteUrl = (route: UtilityRoutes | string) => `${process.env.REACT_APP_HOMEPAGE}/${route}`;

export const PRE_FORM_PAGE_URL = `${process.env.REACT_APP_HOMEPAGE}`;

export const RENEWAL_YOUR_CAR_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.YourCar);
export const RENEWAL_UPDATE_YOUR_CAR_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.UpdateYourCar);
export const RENEWAL_YOUR_CAR_DETAILS_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.YourCarDetails);
export const RENEWAL_YOUR_CAR_USAGE_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.YourCarUsage);
export const RENEWAL_YOUR_PREMIUM_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.YourPremium);
export const RENEWAL_PAYMENT_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.Payment);
export const RENEWAL_CONFIRMATION_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.Confirmation);

export const MIDTERM_START_DATE_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.StartDate);
export const MIDTERM_YOUR_CAR_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.YourCar);
export const MIDTERM_UPDATE_YOUR_CAR_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.UpdateYourCar);
export const MIDTERM_YOUR_CAR_DETAILS_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.YourCarDetails);
export const MIDTERM_YOUR_CAR_USAGE_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.YourCarUsage);
export const MIDTERM_YOUR_PREMIUM_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.YourPremium);
export const MIDTERM_PAYMENT_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.Payment);
export const MIDTERM_REFUND_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.Refund);
export const MIDTERM_REVIEW_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.Review);
export const MIDTERM_DIRECT_DEBIT_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.DirectDebit);
export const MIDTERM_CONFIRMATION_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.Confirmation);

export const UTILITY_CALL_US_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.CallUs);
export const UTILITY_SESSION_TIMEOUT_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.SessionTimeout);
export const UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.SystemUnavailable);
export const UTILITY_ROADBLOCK_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.Roadblock);

export const UPDATE_YOUR_CAR_PAGE_NAVIGATION: Navigation = {
  midterm: MIDTERM_UPDATE_YOUR_CAR_PAGE_URL,
  renewal: RENEWAL_UPDATE_YOUR_CAR_PAGE_URL,
};
export const YOUR_CAR_DETAILS_PAGE_NAVIGATION: Navigation = {
  midterm: MIDTERM_YOUR_CAR_DETAILS_PAGE_URL,
  renewal: RENEWAL_YOUR_CAR_DETAILS_PAGE_URL,
};
export const YOUR_CAR_USAGE_PAGE_NAVIGATION: Navigation = {
  midterm: MIDTERM_YOUR_CAR_USAGE_PAGE_URL,
  renewal: RENEWAL_YOUR_CAR_USAGE_PAGE_URL,
};
export const YOUR_PREMIUM_PAGE_NAVIGATION: Navigation = {
  midterm: MIDTERM_YOUR_PREMIUM_PAGE_URL,
  renewal: RENEWAL_YOUR_PREMIUM_PAGE_URL,
};
export const PAYMENT_PAGE_NAVIGATION: Navigation = {
  midterm: MIDTERM_PAYMENT_PAGE_URL,
  renewal: RENEWAL_PAYMENT_PAGE_URL,
};
export const CONFIRMATION_PAGE_NAVIGATION: Navigation = {
  midterm: MIDTERM_CONFIRMATION_PAGE_URL,
  renewal: RENEWAL_CONFIRMATION_PAGE_URL,
};

export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

const preFormRoutesInfo: Record<PreFormRoute, RouteInformation> = {
  [PreFormRoute.PreForm]: {
    key: "pre-form",
    path: PRE_FORM_PAGE_URL,
    name: PreFormPageNames.PreForm,
    element: <PreForm />,
  },
};

export const renewalRoutesInfo: Record<RenewalRoutes, RouteInformation> = {
  [RenewalRoutes.YourCar]: {
    key: RenewalRoutes.YourCar,
    path: RENEWAL_YOUR_CAR_PAGE_URL,
    name: RenewalPageNames.YourCar,
    heading: RenewalPageHeadings.YourCar,
    element: <YourCar />,
  },
  [RenewalRoutes.UpdateYourCar]: {
    key: RenewalRoutes.UpdateYourCar,
    path: RENEWAL_UPDATE_YOUR_CAR_PAGE_URL,
    name: RenewalPageNames.UpdateYourCar,
    heading: RenewalPageHeadings.UpdateYourCar,
    element: <UpdateYourCar />,
  },
  [RenewalRoutes.YourCarDetails]: {
    key: RenewalRoutes.YourCarDetails,
    path: RENEWAL_YOUR_CAR_DETAILS_PAGE_URL,
    name: RenewalPageNames.YourCarDetails,
    heading: RenewalPageHeadings.YourCarDetails,
    element: <YourCarDetails />,
  },
  [RenewalRoutes.YourCarUsage]: {
    key: RenewalRoutes.YourCarUsage,
    path: RENEWAL_YOUR_CAR_USAGE_PAGE_URL,
    name: RenewalPageNames.YourCarUsage,
    heading: RenewalPageHeadings.YourCarUsage,
    element: <YourCarUsage />,
  },
  [RenewalRoutes.YourPremium]: {
    key: RenewalRoutes.YourPremium,
    path: RENEWAL_YOUR_PREMIUM_PAGE_URL,
    name: RenewalPageNames.YourPremium,
    heading: RenewalPageHeadings.YourPremium,
    element: <YourPremium />,
  },
  [RenewalRoutes.Payment]: {
    key: RenewalRoutes.Payment,
    path: RENEWAL_PAYMENT_PAGE_URL,
    name: RenewalPageNames.Payment,
    heading: RenewalPageHeadings.Payment,
    element: <PaymentDetails />,
  },
  [RenewalRoutes.Confirmation]: {
    key: RenewalRoutes.Confirmation,
    path: RENEWAL_CONFIRMATION_PAGE_URL,
    name: RenewalPageNames.Confirmation,
    element: <Confirmation />,
  },
};

export const preFormRoutes: RouteInformation[] = [preFormRoutesInfo[PreFormRoute.PreForm]];

export const midtermRoutesInfo: Record<MidtermRoutes, RouteInformation> = {
  [MidtermRoutes.StartDate]: {
    key: MidtermRoutes.StartDate,
    path: MIDTERM_START_DATE_PAGE_URL,
    name: MidtermPageNames.StartDate,
    heading: MidtermPageHeadings.StartDate,
    element: <StartDate />,
  },
  [MidtermRoutes.YourCar]: {
    key: MidtermRoutes.YourCar,
    path: MIDTERM_YOUR_CAR_PAGE_URL,
    name: RenewalPageNames.YourCar,
    heading: RenewalPageHeadings.YourCar,
    element: <YourCar />,
  },
  [MidtermRoutes.UpdateYourCar]: {
    key: MidtermRoutes.UpdateYourCar,
    path: MIDTERM_UPDATE_YOUR_CAR_PAGE_URL,
    name: RenewalPageNames.UpdateYourCar,
    heading: RenewalPageHeadings.UpdateYourCar,
    element: <UpdateYourCar />,
  },
  [MidtermRoutes.YourCarDetails]: {
    key: MidtermRoutes.YourCarDetails,
    path: MIDTERM_YOUR_CAR_DETAILS_PAGE_URL,
    name: RenewalPageNames.YourCarDetails,
    heading: RenewalPageHeadings.YourCarDetails,
    element: <YourCarDetails />,
  },
  [MidtermRoutes.YourCarUsage]: {
    key: MidtermRoutes.YourCarUsage,
    path: MIDTERM_YOUR_CAR_USAGE_PAGE_URL,
    name: RenewalPageNames.YourCarUsage,
    heading: RenewalPageHeadings.YourCarUsage,
    element: <YourCarUsage />,
  },
  [MidtermRoutes.YourPremium]: {
    key: MidtermRoutes.YourPremium,
    path: MIDTERM_YOUR_PREMIUM_PAGE_URL,
    name: MidtermPageNames.YourPremium,
    heading: MidtermPageHeadings.YourPremium,
    element: <YourPremium />,
  },
  [MidtermRoutes.Payment]: {
    key: MidtermRoutes.Payment,
    path: MIDTERM_PAYMENT_PAGE_URL,
    name: MidtermPageNames.Payment,
    heading: MidtermPageHeadings.Payment,
    element: <MidTermPayment />,
  },
  [MidtermRoutes.Refund]: {
    key: MidtermRoutes.Refund,
    path: MIDTERM_REFUND_PAGE_URL,
    name: MidtermPageNames.Refund,
    heading: MidtermPageHeadings.Refund,
    element: <MidtermRefund />,
  },
  [MidtermRoutes.Review]: {
    key: MidtermRoutes.Review,
    path: MIDTERM_REVIEW_PAGE_URL,
    name: MidtermPageNames.Review,
    heading: MidtermPageHeadings.Review,
    element: <MidtermReview />,
  },
  [MidtermRoutes.DirectDebit]: {
    key: MidtermRoutes.DirectDebit,
    path: MIDTERM_DIRECT_DEBIT_PAGE_URL,
    name: MidtermPageNames.Review,
    heading: MidtermPageHeadings.Review,
    element: <MidTermDirectDebit />,
  },
  [MidtermRoutes.Confirmation]: {
    key: MidtermRoutes.Confirmation,
    path: MIDTERM_CONFIRMATION_PAGE_URL,
    name: RenewalPageNames.Confirmation,
    element: <Confirmation />,
  },
};

export const renewalRoutes: RouteInformation[] = [
  renewalRoutesInfo[RenewalRoutes.YourCar],
  renewalRoutesInfo[RenewalRoutes.UpdateYourCar],
  renewalRoutesInfo[RenewalRoutes.YourCarDetails],
  renewalRoutesInfo[RenewalRoutes.YourCarUsage],
  renewalRoutesInfo[RenewalRoutes.YourPremium],
  renewalRoutesInfo[RenewalRoutes.Payment],
  renewalRoutesInfo[RenewalRoutes.Confirmation],
];

export const midtermRoutes: RouteInformation[] = [
  midtermRoutesInfo[MidtermRoutes.StartDate],
  midtermRoutesInfo[MidtermRoutes.YourCar],
  midtermRoutesInfo[MidtermRoutes.UpdateYourCar],
  midtermRoutesInfo[MidtermRoutes.YourCarDetails],
  midtermRoutesInfo[MidtermRoutes.YourCarUsage],
  midtermRoutesInfo[MidtermRoutes.YourPremium],
  midtermRoutesInfo[MidtermRoutes.Payment],
  midtermRoutesInfo[MidtermRoutes.Refund],
  midtermRoutesInfo[MidtermRoutes.Review],
  midtermRoutesInfo[MidtermRoutes.DirectDebit],
  midtermRoutesInfo[MidtermRoutes.Confirmation],
];

export const utilityRoutes: RouteInformation[] = [
  {
    key: UtilityRoutes.CallUs,
    path: UTILITY_CALL_US_PAGE_URL,
    name: UtilityPageNames.CallUs,
    element: <CallUs />,
    heading: UtilityPageHeadings.Default,
  },
  {
    key: UtilityRoutes.SessionTimeout,
    path: UTILITY_SESSION_TIMEOUT_PAGE_URL,
    name: UtilityPageNames.SessionTimeout,
    element: <SessionTimeout />,
    heading: UtilityPageHeadings.SessionTimeout,
  },
  {
    key: UtilityRoutes.SystemUnavailable,
    path: UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
    name: UtilityPageNames.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: UtilityPageHeadings.SystemUnavailable,
  },
  {
    key: UtilityRoutes.Roadblock,
    path: UTILITY_ROADBLOCK_PAGE_URL,
    name: UtilityPageNames.Roadblock,
    element: <Roadblock />,
    heading: UtilityPageHeadings.Roadblock,
  },
];

export const allRoutes = [...renewalRoutes, ...midtermRoutes, ...utilityRoutes];

const navigateToUtilityPage = (navigate: NavigateFunction, url: string, virtualPageUrl: string, title: string) => {
  gtm(
    virtualPageView({
      url: virtualPageUrl,
      title,
    }),
  );

  navigate(url, { replace: true });
};

export const handleExceptionNavigation = (
  navigate: NavigateFunction,
  requestName: string,
  isMidterm: boolean | undefined,
  exception?: any,
) => {
  const statusCode = (exception as MotorEndorsementApiException)?.status;

  const createUrl: (input: string) => string =
    isMidterm === true ? getMidtermRouteUrl : isMidterm === false ? getRenewalRouteUrl : getUtilityRouteUrl;

  if (statusCode === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE) {
    navigateToUtilityPage(
      navigate,
      UTILITY_CALL_US_PAGE_URL,
      createUrl("error/299"),
      `Contact out of sync - request: ${requestName}`,
    );
  } else if (statusCode === HTTP_STATUS_CODE_BAD_REQUEST) {
    navigateToUtilityPage(
      navigate,
      UTILITY_CALL_US_PAGE_URL,
      createUrl("error/400"),
      `Road block - request: ${requestName}`,
    );
  } else {
    navigateToUtilityPage(
      navigate,
      UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
      createUrl("error/500"),
      `System unavailable - request: ${requestName}`,
    );
  }
};

export interface UseRoutesResult {
  currentStep: number;
  totalSteps: number;
  onBack: () => void;
}
