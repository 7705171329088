import { LookupServiceApiClient } from "raci-lookup-service-clientproxy";
import { MotorEndorsementApiClient } from "raci-motorendorsements-clientproxy";
import { MfaOtpApiClient } from "raci-otp-service-clientproxy";
import { useSessionIdStorageKey } from "raci-react-library";
import { NpeEnvironment, useSsoApiClient } from "raci-react-library-npe";
import {
  ADB2C_CLIENT_ID,
  BFF_API_BASE_URL,
  LOOKUP_SERVICE_API_BASE_URL,
  MFA_OTP_SERVICE_API_BASE_URL,
} from "../../shared/constants";

const ENVIRONMENT = (process.env.REACT_APP_ENVIRONMENT ?? "") as NpeEnvironment;
const NPE_CLIENT_ID = process.env.REACT_APP_NPE_OAUTH_CLIENT_ID ?? "";
const NPE_AUTHORITY = process.env.REACT_APP_NPE_OAUTH_AUTHORITY ?? "";

export const useNpeBffApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();
  return useSsoApiClient({
    Client: MotorEndorsementApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: BFF_API_BASE_URL,
    sessionIdStorageKey,
    environment: ENVIRONMENT,
    npeClientId: NPE_CLIENT_ID,
    npeAuthority: NPE_AUTHORITY,
  });
};

export const useNpeLookupServiceApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();
  return useSsoApiClient({
    Client: LookupServiceApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: LOOKUP_SERVICE_API_BASE_URL,
    sessionIdStorageKey,
    environment: ENVIRONMENT,
    npeClientId: NPE_CLIENT_ID,
    npeAuthority: NPE_AUTHORITY,
  });
};

export const useNpeMfaOtpServiceApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();
  return useSsoApiClient({
    Client: MfaOtpApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: MFA_OTP_SERVICE_API_BASE_URL,
    sessionIdStorageKey,
    environment: ENVIRONMENT,
    npeClientId: NPE_CLIENT_ID,
    npeAuthority: NPE_AUTHORITY,
  });
};
