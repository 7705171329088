import { LoadingStatus, SessionStatusProviderProps, useClearSession } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { sessionIdAtom } from "../../atoms";
import { CONTEXT_POLICY_NUMBER_SESSION_KEY, SESSION_KEY_PREFIX } from "../../constants";
import { PRE_FORM_PAGE_URL, UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../routing/routes.config";
import useApiClient from "../useApiClient";
import usePolicyNumber from "../usePolicyNumber";

const sessionPrefix = SESSION_KEY_PREFIX || "RAC_MEO_";
export const SESSION_ID_KEY = `${sessionPrefix}SessionId`;

export const useCreateSession: SessionStatusProviderProps["useCreateSession"] = () => {
  const client = useApiClient();
  const navigate = useNavigate();
  const clearSession = useClearSession();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const policyNumber = usePolicyNumber();
  const setSessionId = useSetRecoilState(sessionIdAtom);
  const location = useLocation();

  useEffect(() => {
    let isCancelled = false;

    const createSession = async () => {
      setIsError(false);

      // If we are on our PreForm landing page we want to start fresh with
      // a new session, so clear out any saved session state.
      if (location.pathname === PRE_FORM_PAGE_URL) {
        clearSession(CONTEXT_POLICY_NUMBER_SESSION_KEY);
      }

      const hasSessionId = sessionStorage.getItem(SESSION_ID_KEY);

      try {
        if (!isCancelled && !hasSessionId) {
          const response = await client.createSession();

          sessionStorage.setItem(SESSION_ID_KEY, response.result);
          setSessionId(response.result);

          if (response.status !== 200) {
            setIsError(true);
            navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL, { replace: true });
          }
        }
      } catch {
        setIsError(true);
        navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL, { replace: true });
      } finally {
        setIsLoading(false);
      }
    };

    if (policyNumber) {
      createSession();
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyNumber]);

  return isError ? LoadingStatus.Error : isLoading ? LoadingStatus.Loading : LoadingStatus.Successful;
};

export default useCreateSession;
