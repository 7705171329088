import { RacwaStandardPageTemplateProps } from "@racwa/react-components";
import { useLocation } from "react-router-dom";
import { UtilityPageHeadings, utilityRoutes } from "../../routing/routes.config";
import usePage from "../usePage";

export const useStandardPage = (): RacwaStandardPageTemplateProps => {
  const commonPageProps = usePage();
  const location = useLocation();

  const currentUtilityRoute = utilityRoutes.find(
    (route) => route.path.toLowerCase() === location.pathname.toLowerCase(),
  );

  const heading = currentUtilityRoute?.heading ?? UtilityPageHeadings.Default;

  return {
    heading,
    navBreadcrumbProps: commonPageProps.responsiveHeaderProps?.NavBreadcrumbsProps,
    ...commonPageProps,
  };
};

export default useStandardPage;
