import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RacwaAuth } from "@racwa/react-adb2c";
import { RacwaThemeProvider } from "@racwa/react-components";
import {
  Backdrop,
  SessionStatePrefixProvider,
  SessionStatusProvider,
  TrackPageChanges,
  WebChat,
} from "raci-react-library";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import PolicyNumberProvider from "./shared/components/PolicyNumberProvider";
import {
  ADB2C_CLIENT_ID,
  ADB2C_DOMAIN,
  ADB2C_TENANT,
  SESSION_KEY_PREFIX,
  trackPageChangesProps,
} from "./shared/constants";
import useCreateSession from "./shared/hooks/useCreateSession";
import useGetWebChatInformation from "./shared/hooks/useGetWebChatInformation";
import { applicationInsightsPlugin } from "./shared/utils/applicationInsights";
import Views from "./views";

export const App: React.FC = () => (
  <RacwaAuth domain={ADB2C_DOMAIN} tenant={ADB2C_TENANT} clientId={ADB2C_CLIENT_ID} strict>
    <RacwaThemeProvider>
      <RecoilRoot>
        <AppInsightsContext.Provider value={applicationInsightsPlugin}>
          <Backdrop />
          <BrowserRouter>
            <SessionStatePrefixProvider prefix={SESSION_KEY_PREFIX}>
              <PolicyNumberProvider>
                <SessionStatusProvider useCreateSession={useCreateSession}>
                  <Views />
                  <WebChat useGetWebChatInformation={useGetWebChatInformation} />
                </SessionStatusProvider>
              </PolicyNumberProvider>
            </SessionStatePrefixProvider>
            <TrackPageChanges {...trackPageChangesProps} />
          </BrowserRouter>
        </AppInsightsContext.Provider>
      </RecoilRoot>
    </RacwaThemeProvider>
  </RacwaAuth>
);

export default App;
