export let getB2CPCMBaseUrl: () => string | undefined;

/*
 ** WARNING: KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO
 ** TO THE BELOW IF STATEMENT.
 ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN
 ** THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET
 ** ENVIRONMENT
 ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE
 ** AND SHIP CODE (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED
 ** JAVASCRIPT ARTIFACTS) FOR MICROSOFT AUTHENTICATION LIBRARY
 */
if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  const storageKey = require("../../constants").STORAGE_KEY_RACWA_SOURCE_URL;

  getB2CPCMBaseUrl = () => {
    const url = sessionStorage.getItem(storageKey) ?? process.env.REACT_APP_B2C_PCM_BASE_URL;

    return decodeURIComponent(url ?? "");
  };
} else {
  getB2CPCMBaseUrl = () => process.env.REACT_APP_B2C_PCM_BASE_URL;
}

export const getB2CPCMUrl = (policyNumber: string) => `${getB2CPCMBaseUrl()}?policyNumber=${policyNumber}`;

export default getB2CPCMUrl;
