import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const connectionString = `InstrumentationKey=${process.env.REACT_APP_INSTRUMENTATION_KEY};IngestionEndpoint=${process.env.REACT_APP_INSIGHTS_URL}`;

const applicationInsightsPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: connectionString,
    extensions: [applicationInsightsPlugin as any],
    disableAjaxTracking: true,
    disableFetchTracking: true,
    disableExceptionTracking: false,
  },
});

// Don't load the AppInsights if the connection string will not be valid
if (
  process.env.REACT_APP_INSTRUMENTATION_KEY &&
  process.env.REACT_APP_INSTRUMENTATION_KEY.trim() !== "" &&
  process.env.REACT_APP_INSIGHTS_URL &&
  process.env.REACT_APP_INSIGHTS_URL.trim() !== ""
) {
  appInsights.loadAppInsights();
}

export { applicationInsightsPlugin };
