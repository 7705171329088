import { useRecoilValue } from "recoil";
import { routeStateAtom } from "../../atoms";
import { Navigation } from "../../routing/routes.config";

export const useNavigationResolver = () => {
  const { isMidterm } = useRecoilValue(routeStateAtom);

  const resolve = (route: Navigation) => {
    return isMidterm ? route.midterm : route.renewal;
  };

  return resolve;
};

export default useNavigationResolver;
