import { useGetPreviousPageSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import { ConfirmationData } from "../../../views/Confirmation/hooks/useConfirmationData";
import { policyPaymentStateAtom } from "../../atoms";
import { PolicyPaymentState } from "../../atoms/types";
import {
  ATOM_FORM_COMPLETED,
  ATOM_POLICY_PAYMENT_STATE,
  CONFIRMATION_SESSION_STATE_KEY,
  HAS_TIMED_OUT_STATE_KEY,
} from "../../constants";
import useNavigationResolver from "../../hooks/useNavigationResolver";
import { isEmptyObject } from "../../utils";
import {
  CONFIRMATION_PAGE_NAVIGATION,
  MIDTERM_CONFIRMATION_PAGE_URL,
  PAYMENT_PAGE_NAVIGATION,
  PRE_FORM_PAGE_URL,
  RENEWAL_CONFIRMATION_PAGE_URL,
  UTILITY_CALL_US_PAGE_URL,
  UTILITY_SESSION_TIMEOUT_PAGE_URL,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../routes.config";

export interface RouteGuardProps {
  disableChecks?: string[];
  children?: JSX.Element;
}

export const RouteGuard: React.FC<RouteGuardProps> = ({ disableChecks = [], children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const resolve = useNavigationResolver();
  const resetPolicyPaymentState = useResetRecoilState(policyPaymentStateAtom);
  const { path: previousPageUrl, isCompleted: isPreviousPageCompleted } = useGetPreviousPageSessionState();

  /** On entry to MEO form, ATOM_POLICY_PAYMENT_STATE is not set, so session state will be null  */
  const policyPaymentState = JSON.parse(
    sessionStorage.getItem(ATOM_POLICY_PAYMENT_STATE) ?? "{}",
  ) as PolicyPaymentState;

  /** On entry to MEO form, ATOM_FORM_COMPLETED is not set, so session state will be null  */
  const isFormCompleted = JSON.parse(sessionStorage.getItem(ATOM_FORM_COMPLETED) ?? "false") as boolean;

  const hasTimedOut = JSON.parse(sessionStorage.getItem(HAS_TIMED_OUT_STATE_KEY) ?? "false") as boolean;

  /** ConfirmationData session state is not set until Confirmation page is reached */
  const confirmationData = JSON.parse(
    sessionStorage.getItem(CONFIRMATION_SESSION_STATE_KEY) ?? "{}",
  ) as ConfirmationData;

  const lockDownAllowedRoutes: string[] = [
    RENEWAL_CONFIRMATION_PAGE_URL,
    MIDTERM_CONFIRMATION_PAGE_URL,
    UTILITY_SESSION_TIMEOUT_PAGE_URL,
    UTILITY_CALL_US_PAGE_URL,
    UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
  ];

  /**
   * Checks if the current page can be navigated to by checking if the previous page has been completed.
   * TODO - PreviousPageUrl is always undefined as page indexes are all 0 in RRL useGetPageSessionStorageKeys/getPageIndex.
   * TODO - Potentially look at how useRoutes determines previous page using activeStepIndex and formRoutes.
   */
  useEffect(() => {
    const currentLocation = location.pathname.toLowerCase();
    const bypass = disableChecks.filter((item) => item.toLowerCase() === currentLocation).length > 0;

    if (!bypass && previousPageUrl && !isPreviousPageCompleted) {
      navigate(previousPageUrl, { replace: true });
    }
  }, [navigate, location.pathname, disableChecks, previousPageUrl, isPreviousPageCompleted]);

  /**
   * Forces the user to a particular page, depending on their current flow.
   */
  useEffect(() => {
    const currentLocation = location.pathname.toLowerCase();

    if (currentLocation === UTILITY_SESSION_TIMEOUT_PAGE_URL || hasTimedOut) {
      // If the user is on the timeout page, mark them as timed out
      // If they've timed out and try to navigate elsewhere, send them back to Timed Out
      sessionStorage.setItem(HAS_TIMED_OUT_STATE_KEY, "true");
      resetPolicyPaymentState();

      if (currentLocation !== UTILITY_SESSION_TIMEOUT_PAGE_URL) {
        navigate(UTILITY_SESSION_TIMEOUT_PAGE_URL);
      }
    } else if (lockDownAllowedRoutes.includes(currentLocation)) {
      resetPolicyPaymentState();
    } else if (policyPaymentState?.isPaymentMethodLocked && currentLocation !== resolve(PAYMENT_PAGE_NAVIGATION)) {
      // Redirect user back to payment page if user tries to navigate away when
      // the policy endorsement has been completed and payment method is locked.
      navigate(resolve(PAYMENT_PAGE_NAVIGATION), { replace: true });
    } else if (currentLocation === PRE_FORM_PAGE_URL) {
      // Do nothing as we will clear all session and create a new one (if a policy number is provided in the URL), or
      // redirect them to an error page if it is missing.
      return;
    } else if (!isEmptyObject(confirmationData) && isFormCompleted) {
      // Redirect user back to confirmation page if user tries to navigate away when the form has been completed successfully.
      navigate(resolve(CONFIRMATION_PAGE_NAVIGATION));
    } else if (isEmptyObject(confirmationData) && isFormCompleted) {
      // Redirect user to system unavailable page if form is completed but no confirmation data exists (unsuccessful form completion).
      navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location.pathname, policyPaymentState?.isPaymentMethodLocked, isFormCompleted, confirmationData]);

  return <>{children}</>;
};

export default RouteGuard;
