import { PolicyDetailsResponsePaymentFlow } from "raci-motorendorsements-clientproxy";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PremiumChangeState } from "../../../views/YourPremium/types";
import { policyPaymentStateAtom, routeStateAtom } from "../../atoms";
import getB2CPCMUrl from "../../utils/getB2CPCMUrl";
import {
  MIDTERM_CONFIRMATION_PAGE_URL,
  MIDTERM_DIRECT_DEBIT_PAGE_URL,
  MIDTERM_PAYMENT_PAGE_URL,
  MIDTERM_REFUND_PAGE_URL,
  MIDTERM_REVIEW_PAGE_URL,
  MidtermPageHeadings,
  MidtermPageNames,
  MidtermRoutes,
  RenewalRoutes,
  RouteInformation,
  midtermRoutes,
  preFormRoutes,
  renewalRoutes,
} from "../routes.config";

export interface StepInformation {
  name: string;
  path: string;
  heading?: string;
}

export interface UseRoutesResults {
  steps: StepInformation[];
  preFormRoutes: RouteInformation[];
  formRoutes: RouteInformation[];
  activeStepIndex?: number;
  totalStepCount?: number;
  canNavigateToPreviousStep: boolean;
  canNavigateToDestinationIndex: (destinationRouteIndex: number) => boolean;
  navigateToPreviousStep?: () => void;
  navigateToDestinationIndex: (destinationRouteIndex: number) => void;
  navigateToDestinationStep: (destinationRouteId: RenewalRoutes | MidtermRoutes) => void;
}

const filterRoutes = (routes: RouteInformation[], ...removeKeys: string[]): RouteInformation[] => {
  return routes.filter((item) => !removeKeys.includes(item.key));
};

const isAnnualInstalment = (paymentFlow?: PolicyDetailsResponsePaymentFlow) =>
  paymentFlow === PolicyDetailsResponsePaymentFlow.AnnualInstalmentsPaidInFull ||
  paymentFlow === PolicyDetailsResponsePaymentFlow.AnnualInstalmentsNotPaidInFull;

const isMonthlyFullyPaid = (paymentFlow?: PolicyDetailsResponsePaymentFlow) =>
  paymentFlow === PolicyDetailsResponsePaymentFlow.MonthlyInstalmentsPaidInFull;

const isMonthlyNotFullyPaid = (paymentFlow?: PolicyDetailsResponsePaymentFlow) =>
  paymentFlow === PolicyDetailsResponsePaymentFlow.MonthlyInstalmentsNotPaidInFull;

export const shouldGoToDirectDebitPage = (
  paymentFlow: PolicyDetailsResponsePaymentFlow,
  premiumChangeState: PremiumChangeState | undefined,
) => {
  return (
    isMonthlyNotFullyPaid(paymentFlow) ||
    ((isAnnualInstalment(paymentFlow) || isMonthlyFullyPaid(paymentFlow)) &&
      premiumChangeState !== PremiumChangeState.REFUND)
  );
};

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const navigate = useNavigate();
  const { policyNumber, isMidterm, isVehicleConfirmed, premiumChangeState, paymentFlow } =
    useRecoilValue(routeStateAtom);
  const policyPaymentState = useRecoilValue(policyPaymentStateAtom);
  const isPaymentFlow = premiumChangeState === PremiumChangeState.PAYMENT;
  const isRefundFlow = premiumChangeState === PremiumChangeState.REFUND;
  const policyPaymentFlow = paymentFlow ?? PolicyDetailsResponsePaymentFlow.Unknown;

  let formRoutes = isMidterm ? midtermRoutes : renewalRoutes;
  if (isVehicleConfirmed !== false) {
    formRoutes = filterRoutes(formRoutes, RenewalRoutes.UpdateYourCar);
  }

  if (isMidterm) {
    // Filter Midterm Review routes based on the premium state and policy payment type
    if (shouldGoToDirectDebitPage(policyPaymentFlow, premiumChangeState)) {
      formRoutes = filterRoutes(formRoutes, MidtermRoutes.Payment, MidtermRoutes.Review, MidtermRoutes.Refund);
    } else if (isPaymentFlow) {
      formRoutes = filterRoutes(formRoutes, MidtermRoutes.Review, MidtermRoutes.Refund, MidtermRoutes.DirectDebit);
    } else if (isRefundFlow) {
      formRoutes = filterRoutes(formRoutes, MidtermRoutes.Payment, MidtermRoutes.Review, MidtermRoutes.DirectDebit);
    } else {
      formRoutes = filterRoutes(formRoutes, MidtermRoutes.Payment, MidtermRoutes.Refund, MidtermRoutes.DirectDebit);
    }

    const visibleReviewRoutes = [
      MIDTERM_PAYMENT_PAGE_URL,
      MIDTERM_REFUND_PAGE_URL,
      MIDTERM_REVIEW_PAGE_URL,
      MIDTERM_DIRECT_DEBIT_PAGE_URL,
      MIDTERM_CONFIRMATION_PAGE_URL,
    ];

    // ...but never show the Review routes unless we're sufficiently far enough in the flow
    if (!visibleReviewRoutes.includes(location.pathname)) {
      formRoutes = filterRoutes(
        formRoutes,
        MidtermRoutes.Payment,
        MidtermRoutes.Refund,
        MidtermRoutes.Review,
        MidtermRoutes.DirectDebit,
      );
    }

    const directDebitRoute = formRoutes.find((route) => route.key === MidtermRoutes.DirectDebit);

    // For annual instalment policies in a premium increase state, we send them to the Direct Debit page, with a payment stepper/heading
    if ((isMonthlyFullyPaid(paymentFlow) || isAnnualInstalment(paymentFlow)) && directDebitRoute) {
      if (isPaymentFlow) {
        directDebitRoute.name = MidtermPageNames.Payment;
        directDebitRoute.heading = MidtermPageHeadings.Payment;
      } else {
        directDebitRoute.name = MidtermPageNames.Review;
        directDebitRoute.heading = MidtermPageHeadings.Review;
      }
    }
  }

  const isEndorsementFlow = formRoutes.filter((item) => item.path === location.pathname).length > 0;

  const steps: StepInformation[] = formRoutes;
  const totalStepCount = isEndorsementFlow ? steps.length : undefined;

  const activeStepIndex = isEndorsementFlow ? steps.findIndex((item) => item.path === location.pathname) : undefined;
  const previousPageUrl = activeStepIndex && activeStepIndex > 0 ? formRoutes[activeStepIndex - 1].path : undefined;

  /**
   * TODO - SPK-5192
   * Handle midterm issue where user can navigate back from Midterm Refund/Payment/NoChange Review
   * page using the stepper, then change a rating factor on YourPremium page to trigger a different
   * Midterm scenario (eg NoChange to Refund) and navigate forward using the Browser forward button
   * and jump back to the original Midterm Review page that is no longer valid.
   */
  const navigateToPreviousStep = useMemo(() => {
    if (!isEndorsementFlow) {
      return undefined;
    }

    return previousPageUrl && activeStepIndex !== undefined && activeStepIndex > 0
      ? () => navigate(-1)
      : () => (window.location.href = getB2CPCMUrl(policyNumber ?? ""));
  }, [navigate, isEndorsementFlow, activeStepIndex, previousPageUrl, policyNumber]);

  const canNavigateToPreviousStep =
    !!navigateToPreviousStep &&
    !policyPaymentState?.isPaymentMethodLocked &&
    steps.indexOf(formRoutes[formRoutes.length - 1]) !== activeStepIndex;

  const canNavigateToDestinationIndex = (destinationIndex: number) =>
    canNavigateToPreviousStep === true &&
    activeStepIndex !== undefined &&
    destinationIndex !== -1 &&
    destinationIndex < activeStepIndex;

  const navigateToDestinationIndex = (destinationIndex: number) => {
    if (canNavigateToDestinationIndex(destinationIndex)) {
      navigate(destinationIndex - activeStepIndex!);
    }
  };

  const navigateToDestinationStep = (destinationRouteKey: string) => {
    const destinationIndex = formRoutes.findIndex(({ key }) => key === destinationRouteKey);
    navigateToDestinationIndex(destinationIndex);
  };

  return {
    steps,
    preFormRoutes,
    formRoutes,
    activeStepIndex,
    totalStepCount,
    canNavigateToPreviousStep,
    canNavigateToDestinationIndex,
    navigateToPreviousStep,
    navigateToDestinationIndex,
    navigateToDestinationStep,
  };
};

export default useRoutes;
